<style lang="less" scoped>
.page-header-section {
  background-image: url("../assets/header-bg.jpg");
}
.service-box-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../assets/service-bg.jpg");
}
.promo-box {
  background-image: url("../assets/bg.jpg");
}
</style>

<template>
  <div class="service">
    <section class="page-header-section set-bg">
      <div class="container">
        <h1 class="header-title">Services<span>.</span></h1>
      </div>
    </section>

    <section class="intro-section spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="service-slider swiper-container">
              <div class="swiper-wrapper service-slider">
                <div class="swiper-slide ss-single">
                  <img src="../assets/service/1.jpg"
                       alt="">
                </div>
                <div class="swiper-slide ss-single">
                  <img src="../assets/service/2.jpg"
                       alt="">
                </div>
                <div class="swiper-slide ss-single">
                  <img src="../assets/service/3.jpg"
                       alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7 service-text">
            <h1>We offer top quality project <span>services</span></h1>
            <h2>Great impact on the brand.</h2>
            <p>
              基于“认知科学”为逻辑方法论，对既有品牌营销策略进行全面Rethink并升级迭代，形成一套理论观念和操作框架、流程和工具。提供更加透彻和鲜活的底层理解和逻辑，作用于命名、定位、故事、广告和其他营销场景，可为客户大幅度提升营销和传播效能。
            </p>
            <ol>
              <li>Super-fast support.</li>
              <p class="service-text-p">高速响应</p>
              <li>Clean logic and code.</li>
              <p class="service-text-p">清晰的逻辑</p>
              <li>Detailed documentation.</li>
              <p class="service-text-p">交付细致的文件</p>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <div class="service-box-section spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="solid-service-box">
              <h2>01.</h2>
              <h3>The idea</h3>
              <p>
                创意的诞生不是空洞的装饰，而是品牌、产品、行销与服务的无缝融合，塑造独特的品牌气质，以达成用户对品牌价值认同。
              </p>
              <!-- <a href="#" class="readmore">READ MORE</a> -->
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="solid-service-box">
              <h2>02.</h2>
              <h3>Behind the work</h3>
              <p>
                为客户提供及时响应的支持，其出发点不在于最终呈现的结果，而在于雕琢方案与技术的过程本身，之万的背后是为您待命的千军万马。
              </p>
              <!-- <a href="#" class="readmore">READ MORE</a> -->
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="solid-service-box">
              <h2>03.</h2>
              <h3>The Success</h3>
              <p>
                每一分细节都决定了优质的出品，每一位客户都是我们的老师。我们向任何想进来谈谈的人敞开大门，期待携手寻找尚未定义的成功。
              </p>
              <!-- <a href="#" class="readmore">READ MORE</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="service-section spad">
      <div class="container">
        <div class="section-title">
          <h1>Services</h1>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="service-box">
              <div class="sb-icon">
                <div class="sb-img-icon">
                  <img src="../assets/icon/dark/1.png"
                       alt="">
                </div>
              </div>
              <h3>Digital Marketing</h3>
              <p>
                传递品牌价值 / 占据目标客户的心智 <br />
                数字传播策略 / 自媒体矩阵方案
              </p>
              <a href="portfolio.html"
                 class="readmore">See Project</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="service-box">
              <div class="sb-icon">
                <div class="sb-img-icon">
                  <img src="../assets/icon/dark/2.png"
                       alt="">
                </div>
              </div>
              <h3>Branding Strategy</h3>
              <p>
                品牌定位 / 品牌哲学 / 品牌命名<br />
                品牌口号 / 品牌个性 / 品牌故事
              </p>
              <a href="portfolio.html"
                 class="readmore">See Project</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="service-box">
              <div class="sb-icon">
                <div class="sb-img-icon">
                  <img src="../assets/icon/dark/3.png"
                       alt="">
                </div>
              </div>
              <h3>VI System Design</h3>
              <p>
                品牌标识 logo / 品牌视觉规范手册<br />
                空间环境识别系统 / 包装及吉祥物等
              </p>
              <a href="portfolio.html"
                 class="readmore">See Project</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="service-box">
              <div class="sb-icon">
                <div class="sb-img-icon">
                  <img src="../assets/icon/dark/4.png"
                       alt="">
                </div>
              </div>
              <h3>UI&UED Design</h3>
              <p>
                洞察产品创新点 / 差异化的体验设计<br />
                极致流畅的交互 / 品牌化的界面图标
              </p>
              <a href="portfolio.html?from=ui"
                 class="readmore">See Project</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="service-box">
              <div class="sb-icon">
                <div class="sb-img-icon">
                  <img src="../assets/icon/dark/5.png"
                       alt="">
                </div>
              </div>
              <h3>Events Design</h3>
              <p>
                公关活动创意输出 / 空间搭建团队<br />
                现场拍摄后期剪辑 / 场务资源合作
              </p>
              <a href="portfolio.html?from=events"
                 class="readmore">See Project</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="service-box">
              <div class="sb-icon">
                <div class="sb-img-icon">
                  <img src="../assets/icon/dark/6.png"
                       alt="">
                </div>
              </div>
              <h3>Original Art Toys</h3>
              <p>
                原创IP形象 / 潮玩盲盒 / 雕像手办<br />
                品牌联名合作 / 插画师及原型师孵化
              </p>
              <a href="portfolio.html?from=onetoy"
                 class="readmore">See Project</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="promo-section pt-0">
      <div class="promo-box">
        <div class="container">
          <div class="row">
            <div class="col-lg-9 promo-text">
              <h1>In need of a <span>creative</span> idea?</h1>
              <p>
                We work closely with our clients to deliver the best possible solutions for their needs.
              </p>
            </div>
            <div class="col-lg-3 text-lg-right">
              <a href="javascript:void(0);"
                 @click="routerTo('contact')"
                 class="site-btn sb-light mt-4">Get in Touch</a>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
export default {
  name: 'welcome',
  data () {
    return {
    }
  },
  computed: {
  },
  created () {
  },
  mounted () {
    new Swiper('.swiper-container', {
      autoplay: true
    });
  },
  methods: {
  }
}
</script>
